@import '../variables.scss';

.Onboarding {
    padding: 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &Content {
        flex: 1;
        margin-bottom: 1.5rem;
        border-radius: 5px;
        background-color: $grey;
        padding: 15px;
        height: 1px;
        div {
            overflow: auto;
            height: 100%;
            *:last-child {
                margin-bottom: 0;
            }
        }
    }
    button {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: auto 0 0;
        width: 100%;
        display: block;
        background-color: $primary;
        color: #FFFFFF;
        font-size: 1rem;
    }
}