@import '../variables.scss';

.Holidays {
    padding: 25px;
    display: flex;
    overflow: auto;
    flex-direction: column;

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .RemainingHolidayEntitlement {
        padding: 15px;
        border-radius: 5px;
        background-color: $primary;
        color: #FFFFFF;
        margin: 0;
    }

    .Offline {
        margin: 15px 0 0;
        padding: 15px;
        color: #721c24;
        background-color: #f8d7da;
        border: solid 1px #f5c6cb;
        border-radius: 5px;
    }

    .HolidayGroup {
        background-color: $grey;
        border-radius: 5px;
        margin-top: 1rem;
        header {
            display: flex;
            align-items: center;
            padding: 15px 15px 0;
            strong {
                color: $primary;
            }
            span {
                background-color: $primary;
                color: #FFFFFF;
                width: 32px;
                height: 32px;
                display: flex;
                margin: 0 0 0 auto;
                align-items: center;
                justify-content: center;
                border-radius: 35%;
                svg {
                    width: 12px;
                    height: 12px;
                    transform: rotate(90deg);
                }
            }
        }
        section {
            overflow: hidden;
            transition: all 150ms;
            position: relative;
            padding: 0 15px 15px;

            & > * {
                &:first-child {
                    margin-top: 15px;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    p {
                        margin: 0;
                        font-size: 14px;
                        strong {
                            padding-right: 5px;
                        }
                    }
                    & + li {
                        border-top: solid 1px rgba($dark-grey, 0.3);
                        margin-top: 1rem;
                        padding-top: 0.75rem;
                    }
                }
            }

            form {
                > div {
                    display: grid;
                    gap: 15px;
                    grid-template-columns: 1fr 1fr 1fr;
                    & + div {
                        margin-top: 15px;
                    }
                }
                input, select {
                    display: block;
                    border-radius: 5px;
                    border: none;
                    padding: 1rem;
                    width: 100%;
                    font-size: 1rem;
                    background-color: #FFFFFF;
                    &:disabled {
                        background-color: #FFFFFF;
                    }
                }
                input {
                    grid-column: 1/3;
                }
                select {
                    width: auto;
                }
                p {
                    font-size: 14px;
                    text-align: center;
                }
                button {
                    border-radius: 5px;
                    border: none;
                    padding: 1rem;
                    margin: 1.5rem 0 0;
                    width: 100%;
                    display: block;
                    background-color: $primary;
                    color: #FFFFFF;
                    font-size: 1rem;
                    &:disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
        &.active {
            header {
                span {
                    svg {
                        transform: rotate(270deg);
                    }
                }
            }
        }
        &:not(.active) {
            section {
                max-height: 0;
            }
        }
    }


    small {
        display: inline-block;
        margin: 0 0 0.25rem;
        background-color: $dark-grey;
        color: #FFFFFF;
        padding: 3px 6px 5px;
        line-height: 1;
        font-size: 12px;
        &.pending {}
        &.approved {
            background-color: #07C981;
        }
        &.rejected {
            background-color: #721c24;
        }
    }

    .HolidayModal {
        text-align: left;
        dl {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            dt, dd {
                width: 50%;
                margin: 0;
                min-height: 1.5rem;
            }
            dt {
                font-weight: 600;
            }
            dd {
                text-align: right;
            }
        }
    }
}