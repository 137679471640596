@import '../../variables.scss';

.NotificationView {
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 22px;
        margin: 1rem 0 0;
    }
    small {
        display: block;
        margin: 3px 0 1rem;
    }

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .Back {
        font-weight: 600;
        font-size: 18px;
        text-decoration: none;
        display: block;
        span {
            background-color: $primary;
            color: #FFFFFF;
            width: 32px;
            height: 32px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 35%;
            vertical-align: 0;
            margin: 0 10px 0 0;
            svg {
                width: 12px;
                height: 12px;
                transform: scale(-1);
            }
        }
    }
}