@import '../../variables.scss';

@keyframes error-slidein {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}
div.Notification {
    position: absolute;
    background-color: $primary;
    color: #FFFFFF;
    top: 15px;
    left: 15px;
    right: 15px;
    padding: 15px;
    border-radius: 5px;
    font-size: 0.875rem;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    animation-name: error-slidein;
    animation-duration: 400ms;

    &Title {
    }
}