@import '../variables.scss';

.Installed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &Logo {
        margin: 0 0 1.5rem;
    }
    > p {
        text-align: center;
        color: $primary;
        font-size: 18px;
        margin: 0;
        & + p {
            margin: 1rem 0 0;
        }
    }
}