@import '../variables.scss';

.NotFound {
    background-color: $primary;
    padding: 25px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    img {
        max-width: 50%;
    }

    p {
        color: #FFFFFF;
        font-size: 18px;
        margin: 1rem 0;
    }

    button {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: 1rem 0 0;
        width: 100%;
        display: block;
        background-color: $primary;
        color: #FFFFFF;
        font-size: 1rem;
    }
}