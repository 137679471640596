@import '../../variables.scss';

div.Notifications {
    padding: 25px;
    display: flex;
    flex-direction: column;
    min-height: 100%;

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    h1 {
        font-size: 26px;
        text-transform: uppercase;
        margin: 0 0 1rem 0;
    }

    .NotificationsList {
        flex: 1;
        position: relative;
        h2 {
            font-size: 22px;
            margin: 1rem 0;
            color: $dark-grey;
            font-weight: 600;
        }
        &--Empty {
            margin: 0;
        }

        .refresh-view {
            height: 100%;
            overflow: auto;
        }

        &Busy {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(#FFFFFF, 0.8);
        }
        &Item {
            background-color: $grey;
            border-radius: 5px;
            border: none;
            display: flex;
            width: 100%;
            align-items: center;
            padding: 1rem;
            margin: 0 0 1rem;
            &:last-child {
                margin: 0 0 25px;
            }
            &Content {
                margin: 0 auto 0 0;
                flex: 1;
                text-align: left;
                strong {
                    display: block;
                    color: $primary;
                    font-size: 16px;
                    margin: 0 0 0.35rem;
                }
                span {
                    & + span {
                        &:before {
                            content: '|';
                            margin: 0 7px 0 4px;
                            color: $dark-grey;
                        }
                    }
                }
            }
            > span {
                background-color: $primary;
                color: #FFFFFF;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 35%;
                margin-left: 15px;
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}