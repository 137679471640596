@import '../variables.scss';

@keyframes install_arrow {
    from {
        transform: translate3d(-50%, 0, 0);
    }
    to {
        transform: translate3d(-50%, 15px, 0);
    }
}

.Install {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    &Logo {
        margin: 0 0 1.5rem;
    }
    p {
        text-align: center;
        color: $primary;
        font-size: 18px;
        margin: 0;
    }
    > button {
        border-radius: 5px;
        border: none;
        padding: 1rem;
        margin: 1.5rem 0 0;
        width: 100%;
        display: block;
        background-color: $primary;
        color: #FFFFFF;
        font-size: 1rem;
    }
    &Prompt {
        svg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 15px;
            animation-name: install_arrow;
            animation-duration: 1s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
        }
    }
}