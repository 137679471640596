@import '../../variables.scss';

.PrivateLayout {
    flex: 1;
    display: flex;
    flex-direction: column;
    > header {
        background-color: $primary;
        padding: 15px 25px;
        display: flex;
        align-items: center;

        img {
            max-width: 75px;
            height: auto;
            filter: brightness(0) invert(1);
        }

        .Notifications {
            background-color: transparent;
            padding: 0;
            margin: 0 0 0 auto;
            border: none;
            color: #FFFFFF;
        }
    }
    > main {
        flex: 1;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .Offline {
            padding: 15px 25px;
            color: #721c24;
            background-color: #f8d7da;
            border: solid 1px #f5c6cb;
            border-radius: 5px;
        }
    }
    footer {
        background-color: $primary;
        display: flex;
        justify-content: space-between;
        padding: 5px 25px 0;
        button {
            border: none;
            background-color: transparent;
            color: #FFFFFF;
            padding: 15px;
            opacity: 0.5;
            &.active {
                opacity: 1;
            }
        }
    }
}